import Router from 'next/router';

function logOut() {
  if (typeof window != 'undefined') {
    sessionStorage.removeItem('kreebaToken');
    Router.push('/');
  }
}

module.exports = logOut;
