/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useState } from 'react';
import Link from 'next/link';
import ActiveLink from '../ActiveLink';
import { Dialog, Transition } from '@headlessui/react';
import {
  CalendarIcon,
  ChartBarIcon,
  HomeIcon,
  InboxIcon,
  MenuIcon,
  UsersIcon,
  UserGroupIcon,
  XIcon,
  LogoutIcon,
} from '@heroicons/react/outline';
import useToken from '../../hooks/useToken';
import logOut from '../../utils/logout';

const navigation = [
  {
    name: 'Dashboard',
    href: '/',
    icon: HomeIcon,
    current: false,
    accountTypes: ['kreeba', 'client'],
    role: ['owner', 'admin'],
  },
  {
    name: 'Clientes',
    href: '/clientes',
    icon: UsersIcon,
    current: false,
    accountTypes: ['kreeba'],
    role: ['owner', 'admin'],
  },
  {
    name: 'Desarrollos',
    href: '/desarrollos',
    icon: ChartBarIcon,
    current: false,
    accountTypes: ['client', 'kreeba'],
    role: ['agent', 'controller', 'admin'],
  },
  {
    name: 'Configuración',
    href: '/configuracion',
    icon: CalendarIcon,
    current: false,
    accountTypes: ['kreeba'],
    role: ['everyone'],
  },
  {
    name: 'Usuarios',
    href: '/usuarios',
    icon: UserGroupIcon,
    current: false,
    accountTypes: ['client', 'kreeba'],
    role: ['admin'],
  },
  {
    name: 'Contactos',
    href: '/contactos',
    icon: InboxIcon,
    current: false,
    accountTypes: ['client', 'kreeba'],
    role: ['everyone'],
  },
];

const verifyAccess = (item, accountType, role) => {
  const accountTypeResult =
    item.accountTypes.some((acc) => accountType.includes(acc)) ||
    item.accountTypes.includes('everyone');
  const roleResult =
    item.role.some((r) => role.includes(r)) || item.role.includes('everyone');

  return accountTypeResult && roleResult;
};

export default function Example(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const { token, error: tokenError, tokenData } = useToken();

  return (
    <div className="h-screen flex overflow-hidden bg-gray-50">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 flex z-40 md:hidden"
          open={sidebarOpen}
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <div className="flex-shrink-0 flex items-center px-4">
                  <img
                    className="h-8 w-auto"
                    src="/img/kreeba_logo_color.svg"
                    alt="Workflow"
                  />
                </div>
                <nav className="mt-5 px-2 space-y-1">
                  {navigation.map((item, index) => (
                    <ActiveLink href={item.href} key={index}>
                      <a>{item.name}</a>
                    </ActiveLink>
                  ))}
                </nav>
              </div>
              <div className="flex-shrink-0 flex border-t border-primary-800 p-4">
                <Link
                  href="/perfil"
                  className="cursor-pointer flex-shrink-0 group block"
                >
                  <div className="cursor-pointer flex items-center">
                    <div>
                      <img
                        className="inline-block h-10 w-10 rounded-full"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                      <p className="text-base font-medium text-primary-500">
                        {tokenData?.name}
                      </p>
                      <p className="text-sm font-medium text-secondary-600 group-hover:text-secondary-700">
                        Ver Perfil
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
              <div
                onClick={logOut}
                className="p-3 inline-flex text-sm cursor-pointer text-gray-600 hover:text-secondary-600"
              >
                <LogoutIcon className="w-5 mr-2 " />
                <span>Cerrar sesión</span>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden bg-white md:flex shadow-md md:flex-shrink-0">
        <div className="flex flex-col w-64">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col h-0 flex-1">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-4">
                <img
                  className="h-8 w-auto"
                  src="/img/kreeba_logo_color.svg"
                  alt="Workflow"
                />
              </div>
              <nav className="mt-5 flex-1 px-2 space-y-1">
                {tokenData &&
                  navigation.map(
                    (item, index) =>
                      verifyAccess(
                        item,
                        tokenData.accountType,
                        tokenData.role,
                      ) && (
                        <ActiveLink href={item.href} key={index}>
                          <a href={item.href}>
                            <item.icon
                              className="mr-3 h-6 w-6  inline-flex"
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        </ActiveLink>
                      ),
                  )}
              </nav>
            </div>

            <div className="flex-shrink-0 flex border-t border-primary-500 p-4">
              <Link
                href="/perfil"
                className="cursor-pointer flex-shrink-0 w-full group block"
              >
                <div className=" cursor-pointer flex items-center">
                  <div>
                    <img
                      className="inline-block h-9 w-9 rounded-full"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-primary-500">
                      {tokenData?.name}
                    </p>
                    <p className="text-xs font-medium text-secondary-600 group-hover:text-secondary-700">
                      Ver Perfil
                    </p>
                  </div>
                </div>
              </Link>
            </div>
            <div
              onClick={logOut}
              className="p-3 inline-flex text-sm cursor-pointer text-gray-600 hover:text-secondary-600"
            >
              <LogoutIcon className="w-5 mr-2 " />
              <span>Cerrar sesión</span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-secondary-700"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <div className="py-6">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8"></div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-0 md:mt-14">
              {/* Replace with your content */}
              {props.children}
              {/* /End replace */}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
