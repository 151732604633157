import { useEffect } from 'react';
import Layout from '../components/layout';
import '../styles/global.css';
import useToken from '../hooks/useToken';

function MyApp({ Component, pageProps }) {
  const { token, error: tokenError } = useToken();

  return (
    <>
      {!tokenError ? (
        <Layout>
          <Component {...pageProps} />
        </Layout>
      ) : (
        <div>
          <Component {...pageProps} />
        </div>
      )}
    </>
  );
}

export default MyApp;
