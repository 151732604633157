import { useRouter } from 'next/router';
import Link from 'next/link';
import React, { Children, useEffect, useState } from 'react';

const ActiveLink = ({ children, activeClassName, href, ...props }) => {
  const [activeClass, setActiveClass] = useState(
    'text-white hover:bg-indigo-600 hover:bg-opacity-75 group flex items-center px-2 py-2 text-base font-medium rounded-md',
  );
  const { asPath } = useRouter();
  const child = Children.only(children);

  useEffect(() => {
    const className =
      asPath === href
        ? 'text-secondary-600 text-white '
        : 'text-gray-500 hover:text-secondary-500 hover:bg-opacity-75';
    setActiveClass(className);
  }, [asPath]);

  return (
    <Link href={href} {...props}>
      <div
        className={`${activeClass} group flex items-center p-4 text-sm font-medium rounded-md cursor-pointer`}
      >
        {React.cloneElement(child)}
      </div>
    </Link>
  );
};

export default ActiveLink;
