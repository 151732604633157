import jwt_decode from 'jwt-decode';
import { useRouter } from 'next/router';
import { fromUnixTime } from 'date-fns';

export default function useToken() {
  const Router = useRouter();
  let error = false;
  let token;
  let tokenData;

  if (typeof window != 'undefined') {
    if (sessionStorage.getItem('kreebaToken')) {
      token = sessionStorage.getItem('kreebaToken');
      tokenData = jwt_decode(token);
      var sessionExpirationDate = fromUnixTime(tokenData.exp);
      if (sessionExpirationDate < new Date()) {
        error = new Error('The token has expired');
        sessionStorage.removeItem('kreebaToken');
        if (Router.pathname !== '/iniciar-sesion') {
          Router.push('/iniciar-sesion');
        }
      }
    } else {
      error = new Error('There is no token');
      if (
        Router.pathname !== '/iniciar-sesion' &&
        Router.pathname !== '/unirse'
      ) {
        Router.push('/iniciar-sesion');
      }
    }
  }

  return { error, token, tokenData };
}
